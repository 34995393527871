


























































import {Component, Vue} from "vue-property-decorator";
import InvitationCompletionRequestDTO from "@/dto/auth/InvitationCompletionRequestDTO";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {namespace} from "vuex-class";
import RouteNames from "@/router/RouteNames";
import PortalInput from "@/components/common/PortalInput.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import Notifications from "@/state/Notifications";

const Auth = namespace("Auth");

@Component({
	components: {PortalInput}
})
export default class InvitationCompletion extends Vue {

  private successful = false;

  private message = "";

  private submitted = false;

        private loading = false;

        private request = new InvitationCompletionRequestDTO(this.$route.params.invitationCode);

        @Auth.Action
        private completeInvitation!: (request: InvitationCompletionRequestDTO) => Promise<any>;

        handleRegister() {
            this.message = "";
			ifValid(this, () => {
				this.loading = true;
				this.completeInvitation(this.request).then(
					(data) => {
						this.loading = false;
						Notifications.success("Your account registered successfully");
						this.successful = true;
						this.submitted = true;
						this.$router.push({name: RouteNames.HOME});
					},
					err => processError(err, this)
				);
			})
        }

    }
