export default class InvitationCompletionRequestDTO {

    public invitationCode = "";

    public password = "";

    public confirmPassword = "";

    constructor(invitationCode: string) {
        this.invitationCode = invitationCode;
    }


}